import React, {Component} from "react";
import logo from './logo.svg';
import './App.css';

import { Helmet, HelmetProvider } from 'react-helmet-async';
//import firebase from "firebase/app";

//import ProminentAppBar from "./ProminentAppBar.js";
import MommiPersistentDrawerLeft from "./PersistentDrawerLeft.js";
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
// import cyan from '@material-ui/core/colors/cyan';
// import blue from '@material-ui/core/colors/blue';
// import pink from '@material-ui/core/colors/pink';
//import deepOrange from '@material-ui/core/colors/deepOrange';
import firebase from "firebase/app";
import { theFirebaseConfig } from "./fb.config";
//Use the associated firebase config
// const firebaseConfig = theFirebaseConfig.stagingFirebaseConfig;
// const firebaseConfig = theFirebaseConfig.prodFirebaseConfig;

let firebaseConfig = theFirebaseConfig.stagingFirebaseConfig;
if(window._env_.ENV == "production") {
  firebaseConfig = theFirebaseConfig.prodFirebaseConfig;
} 

const theme = createTheme({
  
    // palette: {
    //   primary: lavender,
    //   secondary: deepOrange,
    // },
    palette: {
      primary: {
        main: '#ce93d8',
      },
      secondary: {
        main: '#ffadc8',
      }
    },
});

class App extends React.Component {
  constructor(props) {
    super(props);

    if (firebase.apps.length === 0) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      console.log("Initialized firebase!")
    }

  }

  render()  {

    return (
      <div >
        <HelmetProvider>
          <Helmet>
            <title>Mommi - Admin</title>
            <script src="https://www.gstatic.com/firebasejs/8.4.2/firebase-app.js"></script>
            <script src="https://www.gstatic.com/firebasejs/8.4.2/firebase-analytics.js"></script>
          </Helmet>
        </HelmetProvider>
        

        {/* common theme for the app*/}
        <ThemeProvider theme={theme}>
          
          {/* ensure no cache by adding query parameter */}
          <script src="env-config.js"></script>
          
          <MommiPersistentDrawerLeft />
         
          {/* <p>API_URL: {window._env_.API_URL}</p> */}
          {/*
          <img src={logo} className="App-logo" alt="logo" />
          <p className="App-intro">{this.state.apiResponse}</p>
          */}
          
          
        </ThemeProvider>
        

      </div>
    );

  }
  
}

export default App;
