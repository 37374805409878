import React, { Component, useEffect, useState, useRef } from "react";
import {
    useParams
  } from "react-router-dom";
  
// import {
// BrowserRouter as Router,
// Switch,
// Route,
// Link
// } from "react-router-dom";
// import Grid from '@material-ui/core/Grid';
// //import FirebaseSetup from "./FirebaseSetup";
// import Paper from '@material-ui/core/Paper';
// import DataTable from 'react-data-table-component';
// import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
//import MaterialTable from "material-table";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Send from '@material-ui/icons/Send';
import Sms from '@material-ui/icons/Sms';
import Refresh from '@material-ui/icons/Refresh';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EventIcon from '@material-ui/icons/Event';

//import CBTextingModal  from "./TextingModal";
import MommiTextingModal  from "./TextingModal";
import cbRequest from "./CBRequest.js";
import cbFirestore from "./CBFirestore.js";
//import SmsBadgeIcon from "./SmsBadgeIcon.js";
//import firebase from "firebase/app";
import Fuse from 'fuse.js'
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

//const firebase = require("firebase");
// Required for side-effects
//require("firebase/firestore");
const _ = require("underscore");
// Load the full build.
const _lodash = require('lodash');
const moment = require("moment");
//const Fuse = require('fuse.js')

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Send : forwardRef((props, ref) => <Send {...props} ref={ref} />),
    Sms : forwardRef((props, ref) => { console.log(props); return <Sms {...props} ref={ref} /> }),
    EventIcon : forwardRef((props, ref) => <EventIcon {...props} ref={ref} />),
    Refresh : forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
};

function FirebaseDatatableRequest(props) {

    const [allData, setAllData] = useState([]);
    const [data, setData] = useState([]);
    const [cols, setCols] = useState([]);
    const { mid,tid } = useParams(); 
    //const [totalRows, setTotalRows] = useState(10);
    const [displayedTextHistory, setDisplayedTextHistory] = useState(false);
    var rawData = useRef([]);
    var textModalId = useRef("");

    const [modal, setModal] = useState({
        title : "", //Mother's name
        id : "",
        showModal : false,
        phone : "",
        textHistory : [],
        lastUpdate: moment().format("MM-DD-YYYY_HH:mm:ss"),
        menuOpen : props.menuOpen,
        disableSMS: false,
        isAdmin : props.isAdmin,
        isCoach : props.isCoach
    });

    const tableRef = React.createRef();

    useEffect(async () => {

        setCols( await loadTableColumns() )

        const db = cbRequest.getFirestore();
        // Set the top level collection to what's in the URL
        //const topLevelCollection = cbRequest.getTopLevelCollection();
        const topLevelCollection = tid;
        const docMap = await cbRequest.extractDocMap(topLevelCollection);
        // console.log("docMap ", docMap)
        // const unsubscribe = db.collection(props.collection)
        const unsubscribe = db.collection("/" + topLevelCollection + "/"+ docMap[props.collection] + "/" + props.collection)
          .onSnapshot(async snapshot => {
            // Data placeholder
            var ddata = {
                "added" : [],
                "removed" : [],
                "modified" : []
            };

            if (snapshot.size) {
                // we have something
                //** Handle returned data *
                snapshot.docChanges().forEach( change => {
                    const d = change.doc.data();
                    const t = change.type;
                    const source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    //console.log(source)
                    if (props.collection == "automation" && "action" in d) {
                        //extract the type
                        d["action"] = d["action"]["type"];
                    }
                    if (t == "modified") {
                        console.log(d)
                    }
                    // 
                    ddata[t].push(d);
                });
            } 

            console.log("handle data ", ddata);
            const d = await processRetrievedData(ddata);
            rawData = d;
            setAllData( d )

            //Update the table data
            setData( d )
            // console.log("DATA IS: " + JSON.stringify(d, null , 2)  );
            //Ensure a check to the modal is performed
            //setDisplayedTextHistory(true);
            
            //
            cbRequest.popTextHistory = (m_index) => {
                console.log("Load data for mom ", m_index)
                if (m_index == null) return;
                
                //find mom
                //const mom = _.filter(this.state.data, (o) => {
                const mom = _.filter(d, (o) => {    
                    return o.index == m_index;
                })
                console.log("popTextHistory MOM IS: ", mom)
                //
                textModalId = mom[0]["index"];

                if (_.isNull(mom)) return;
                if (mom.length == 0) {  return };
                setModal({
                    title : mom[0]["first_name"] + ' ' + mom[0]["last_name"], 
                    id : mom[0]["index"] , 
                    phone : mom[0]["telephone"],
                    textHistory : mom[0]["text_history"],
                    //showModal: true,
                    menuOpen : false,
                    triggerAppearance : true,
                    //lastUpdate: moment().format("MM-DD-YYYY_HH:mm:ss")
                    disableSMS: 
                      mom[0]["sms_opt_out"] == true ? true : 
                      (typeof mom[0]["sms_opt_out"] != "string") ? false : 
                      (mom[0]["sms_opt_out"]).toLowerCase() == "true" || (mom[0]["sms_opt_out"]).toLowerCase() == "yes" ? true : false
                        
                })
            }

            if ( textModalId.length > 0){
                console.log("Modal is displayed! update text history ")
                cbRequest.popTextHistory(textModalId)
            }
        })

        console.log("Datatable urlPath: ", mid)
        if (!_.isUndefined(mid)) {
            // Load the mother's text history
            if (mid.length != 0 && mid != "all") {
                //Show modal
                _.delay(() => {
                    cbRequest.popTextHistory(mid)
                },1500);
            }
        }
        
        return () => {     
            unsubscribe()
        }
    },[]);

    useEffect( () => {
        console.log("Update to modal: ", props.menuOpen)
        setModal({
            ...modal,
            menuOpen : props.menuOpen,
        })
    }, [props.menuOpen])

    // useEffect(()=>{
    //     console.log("Check if modal is displayed and sync text history")
    // }, displayedTextHistory)

    const loadTableColumns = async () => {
        console.log("loadTableColumns ", props)
        //Obtain the columns associated with this table
        const resp = await cbRequest.getTableColumnsFor(props.collection, tid);

        var tblCols = (resp != null) ? Object.values(resp) : [];

        // if (props.collection) {
        //     for (const col_ix in tblCols) {
        //         if (tblCols[col_ix].field == "discharge_date") {
        //             tblCols[col_ix].defaultSort = "desc";
        //             continue;
        //         }
        //     }
        // }

        console.log("tblCols: ", tblCols);

        if(!props.isAdmin) {
          tblCols.map((col) => {
            // console.log(col.field)
            if(col.field !== "handoff") {
              col.editable = "never";
            } else {
              col.editable = "always";
            }
          })
        }

        tblCols.map((col) => {
          if(col.field == "handoff") {
            col['cellStyle'] = {};
            col.cellStyle.minWidth = '300px'; // handoff
          }
          if(col.field == 'SMS' || col.field == 'first_name') {
            console.log('col matches');
            col['headerStyle'] = {};
            col.headerStyle.pointerEvents = 'none';
          }
          if(col.field == 'enrollment_date') {
            col.defaultSort = 'desc';
          }
        })

        // console.log("modified tbleCols: " + JSON.stringify(tblCols, null, 2))

        return tblCols;
    }

    var processRetrievedData = async (resp) => {
        var tblData = (resp != null) ? resp : [];
        // Current table data
        var this_d = ("current" in rawData) ? rawData.current : rawData;
        //var this_d = [];

        //Handle the configuration data 
        if (props.collection == "config") {
            // var filteredTblData = {
            //     added : [],
            //     modified : [],
            //     removed : []
            // }
            var filteredTblData = [];
            _.map(tblData, (value, key) => {
                // key is 'added', 'modified', 'removed'

                value.forEach( (o,i) => {
          
                    _.map(o.topLevelCollections, (site_name, c_name) => {
                        // filteredTblData[key].push({
                        //     topLevelCollections: o.topLevelCollections[j],
                        //     index : o.topLevelCollections[j]
                        // });
                        if ( key != "removed") {
                            filteredTblData.push({
                                topLevelCollections: c_name,
                                site_name : site_name,
                                index : c_name
                            });
                        }
                    })
                    

                })
            })
            filteredTblData = _.filter(filteredTblData, (o) => {
                return _.isString(o.topLevelCollections);
            })
            tblData = filteredTblData;

            return [...tblData];
        }

        //Remove data 
        if ("removed" in tblData && tblData["removed"].length > 0 ) {
            //get the id's of data to remove
            const idsToRemove = _.map(tblData.removed, (o) => {
                return o.index;
            });

            //Get all data besides the ids to remove
            this_d = this_d.filter(item => idsToRemove.indexOf(item.index) == -1 );
        }
        //Add data
        if ("added" in tblData && tblData["added"].length > 0) {
            //ensure to only add different data
            //..get the indexs to add
            const idsToAdd =_.map(tblData.added, (o) => {
                return o.index;
            }); 
            //get list of current indices
            const currentIndices = _.map(this_d, (o) => {
                return o.index;
            })
            // check if new data doesn't exist in current indices
            const newDataIds = _.filter(idsToAdd, (o) => {
                return currentIndices.indexOf(o) == -1;
            })

            if (newDataIds.length > 0) {
                const dataToAdd = cbRequest.formatDataForDataTable(_.filter(tblData.added, (o) => {
                    return newDataIds.indexOf(o.index) != -1;
                }));
                this_d = this_d.concat(dataToAdd);
            }
            
        }
        if ("modified" in tblData && tblData["modified"].length > 0) {

            //this.loadTableWith(null); return;
            //get all the keys (index's) that have been modified
            const indexToM = {};
            const indexToTelephone = {};
            const modKeys = _.map(tblData.modified, (o,i) => { 
                indexToM[o.index] = o;
                indexToTelephone[o.index] = o.telephone;
                return o.index;
            });
            const matchingTelephone = _.values(indexToTelephone);

            // find matches based on 'index'; filter out matching indices
            const updated = _.filter(_.map(this_d, (o,i) => {
                if ( 'index' in o && !_.isUndefined(o.index) && modKeys.indexOf(o.index) > -1 ) {
                    return i;
                }
                else{ 
                    return -1;
                }
            }), (o) => { return o > -1});
            console.log("Updated: ", updated)
            // updated.forEach( (x) => {
            //     this_d[x] = 
            // })
            //needs to just add this
            if (updated.length == 0) {
                // const dataToAdd = cbRequest.formatDataForDataTable(tblData.modified);
                // this_d = this_d.concat(dataToAdd);
                const indexToUpdate = {};
                // Check if the mother telphone number matches
                const telephoneMatches = _.filter(_.map(this_d, (o,i) => {
                    // if ( 'telephone' in o && indexToM[o.index].telephone ) {
                    if ( matchingTelephone.indexOf(o.telephone) > -1) {
                        indexToUpdate[o.index] = indexToM[o.index];
                        return i;
                    }
                    else{ 
                        return -1;
                    }
                }), (o) => { return o > -1});
                console.log("telephone matches ", telephoneMatches)
                // _.map(telephoneMatches, (o,i) => {
                //     this_d[o] = indexToUpdate[this_d[o].index];
                //     this_d[o].id = o;
                // })
                if (telephoneMatches.length > 0 ) {
                    setTimeout(() => {
                        document.location.reload();
                    },2000)
                    
                }
            }
            else {
                //
                _.map(updated, (ix) => {
                    const m = _.extend(this_d[ix], indexToM[this_d[ix].index]);

                    // 1. Make a shallow copy of the items
                    let items = [...this_d];
                    // 2. Make a shallow copy of the item you want to mutate
                    //let item = {...items[ix]};
                    // 3. Replace the property you're intested in
                    //item.name = 'newName';
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    items[ix] = cbRequest.formatDataForDataTable([m])[0]; 
                    this_d = items;
                    // this.setState({
                    //     data : [
                    //         ...this.state.data.slice(0,ix),
                    //         cbRequest.formatDataForDataTable([m])[0],
                    //         ...this.state.data.slice(ix+1)
                    //     ]
                    // });
                    // const m = _.extend(indexToM[this_d[ix].index], this_d[ix])
                    // return [this_d[ix].index, cbRequest.formatDataForDataTable([m])[0] ];
                })
            }
            

        }
        var theData = [...this_d];
        // var data = cbRequest.formatDataForDataTable(tblData);
        //var data = cbRequest.formatDataForDataTable(this_d);
        console.log("retrieveDataFor: ", theData);

        theData.map((item) => {
          // calculate preday, postday and babyage
          if(!!item.baby_birthdate && item.baby_birthdate.length > 0) {
            if(!!Date.parse(item.baby_birthdate)) {
              // mommi has delivered
              let current_date = moment();
              let enrollment_date = moment(item.enrollment_date);
              let birth_date = moment(item.baby_birthdate);

              item.preday = "0";
              item.postday = current_date.diff(enrollment_date, 'days');
              item.baby_age = current_date.diff(birth_date, 'days');

              // console.log("")
            }
          } else {
            // mommi is pregnant
            let current_date = moment();
            let enrollment_date = moment(item.enrollment_date);
            // let birth_date = moment(item.baby_birthdate);

            item.preday = current_date.diff(enrollment_date, 'days');
            item.postday = "0";
            item.baby_age = "0";
          }


          // iff the values for pregnant, nicu, completed_consent and sms_opt_out have true or false, change it show yes or no
          // the values could be true, false, "true", "false", undefined, "undefined" 
          // pregnant
          if(item.pregnant == true || item.pregnant === "true") {
            item.pregnant = "Yes"; //0;
            // item.pregnant = 0;
          } else if(item.pregnant == false || item.pregnant === "false") {
            item.pregnant = "No"; //1;
            // item.pregnant = 1;
          } 
          // else {
          //   item.pregnant = 2;
          // }

          // nicu
          if(item.nicu == true || item.nicu === "true") {
            item.nicu = "Yes"; //0;
            // item.nicu = 0;
          } else if(item.nicu == false || item.nicu === "false") {
            item.nicu = "No"; //1;
            // item.nicu = 1;
          } 
          // else {
          //   item.nicu = 2;
          // }

          // completed_consent
          // console.log("completed consent is ", item.completed_consent)
          if(item.completed_consent == true || item.completed_consent === "true") {
            item.completed_consent = "Yes"; //0;
            // item.completed_consent = 0;
          } else if(item.completed_consent == false || item.completed_consent === "false") {
            item.completed_consent = "No"; //1;
            // item.completed_consent = 1;
          } 
          // else {
          //   item.completed_consent = 2;
          // }  

          // sms opt out
          if(item.sms_opt_out == true || item.sms_opt_out === "true") {
            item.sms_opt_out = "Yes"; //0;
            // item.sms_opt_out = 0;
          } else if(item.sms_opt_out == false || item.sms_opt_out === "false") {
            item.sms_opt_out = "No"; //1;
            // item.sms_opt_out = 1;
          } 
          // else {
          //   item.sms_opt_out = 2;
          // }
        })

        if (props.collection === "mothers") {
            return await _addClinicNames(theData)
        } else {
            console.log("returning DATA: ", theData);
            return theData;
        }

    }

    const _addClinicNames = async (data) => {
        const clinicNames = await cbRequest.getClinicCodeNames(props.collection, tid);
        return data.map((item) => {
            if(!!item.clinic_code && item.clinic_code.length > 0) {
                if(!!clinicNames[item.clinic_code]) {
                    item.clinic_name = clinicNames[item.clinic_code];
                }
            }
            return item;
        })
    }

    function _exportPDF(cols, datas) {
        return ExportPdf(cols, allData, props.collection + "_" + moment().format("MM-DD-YYYY_HH:mm:ss") )
    }

    function _exportCsv(cols, datas) {
        return ExportCsv(cols, allData, props.collection + "_" + moment().format("MM-DD-YYYY_HH:mm:ss") )
    }

      return (
     //   
        //Wrap in a div
        <div>
            {/* Modal */}
            {/* <CBTextingModal modal={this.state.modal}/> */}
            <MommiTextingModal modal={modal}/>

            {/* Table */}
            <MaterialTable
                tableRef={tableRef}
                title={props.title}
                //key={}
                icons={tableIcons}
                options={{
                    pageSize: (props.collection == "config") ? 5 :  100 ,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    emptyRowsWhenPaging: false,
                    paging : true,
                    searchFieldAlignment : "left",
                    actionsColumnIndex : (props.collection == "mothers") ? 1 : 0,
                    exportButton: true,
                    sorting : true,
                    padding:"dense",
                    draggable : true,
                    addRowPosition : "first",
                    exportMenu: [{
                        label: 'Export PDF',
                        exportFunc: (cols, datas) => _exportPDF(cols, datas)
                    }, {
                        label: 'Export CSV',
                        exportFunc: (cols, datas) => _exportCsv(cols, datas)
                    }]
                }}
                columns={cols}
                data={data}
                editable={{
                    isEditHidden : (rowData) => {
                        // return (props.isAdmin) ? false : true;
                        return false;
                        // check for handoff field and isCoach then true for one field @todo
                    },
                    isDeleteHidden : (rowData) => {
                        return (props.isAdmin) ? false : true;
                    },
                    isEditable : (rowData) => {
                        
                        // if (props.collection == "config") {
                        //     return false;
                        // }
                        // return true;
                        // console.log("rowdata is ...............");
                        // console.log(rowData);
                        // return (props.isAdmin) ? true : false; 

                        return true;
                        
                        // check for handoff field and isCoach then true for one field @todo
                    },
                    isDeletable : ( rowData ) => {
                        return (props.isAdmin) ? true : false;
                        // return true
                    },
                    onRowAdd: newData =>                    
                      new Promise(async (resolve, reject) => {

                        if (!props.isAdmin) {
                            window.alert("You must be an administrator to add")
                            reject()
                            return;
                        }
                        if (props.collection == "config") {

                            // Update config to add this site
                            await cbFirestore.addNewSiteToConfig(newData.topLevelCollections, newData.site_name);
                            //Create the base structure 
                            await cbFirestore.createNewTopLevelCollection(newData.topLevelCollections);
                            // Use pilot as basis for automation and config
                            await cbFirestore.setupFromPilotTo(newData.topLevelCollections);

                            resolve();
                            return 
                        }
                        cbRequest.addDataTo(props.collection, newData, tid).then( (idCreated) => {

                            console.log("Document added!")
                            resolve();

                        }, (error) => {
                            console.log("addDataTo error: ", error)
                            reject(error);
                        });

                      }),
                    onRowUpdate: (newData, oldData) =>

                      new Promise(async (resolve, reject) => {

                        if (props.collection == "config") {
                            if (oldData.index == "clients" || oldData.index == "pilot" || oldData.index == "d2c") {
                                window.alert("This collection can not be changed.");
                                reject()
                                return;
                            }
                            // for await ( const o of cbFirestore.pilotCollections().map( (c) => {
                            //     const p = "/" + oldData.index + "/" + 
                            cbFirestore.copyCollectionTo(oldData.index, newData.topLevelCollections)
                            // }))
                            //Proceed with the update to the collection
                            // await cbFirestore.copyCollectionTo(oldData.index, newData.topLevelCollections)
                            // Update config to add this site
                            await cbFirestore.addNewSiteToConfig(newData.topLevelCollections, newData.site_name);
                            await cbFirestore.deleteSiteFromConfig(oldData.topLevelCollections);
                            //delete the old site
                            await cbFirestore.deleteSiteFromTopLevel(oldData.topLevelCollections)
                            resolve();
                            return;
                        }
                        // const dataUpdate = [...this.state.data];
                        // const index = oldData.tableData.id;
                        // dataUpdate[index] = newData;
                        //setData([...dataUpdate]);
                        console.log("onRowUpdate newData is ", newData)

                        // if(newData["pregnant"] == 0) {
                        //   newData["pregnant"] = true;
                        // } else if(newData["pregnant"] == 1) {
                        //   newData["pregnant"] = false;
                        // }

                        // if(newData["nicu"] == 0) {
                        //   newData["nicu"] = true;
                        // } else if(newData["nicu"] == 1) {
                        //   newData["nicu"] = false;
                        // }

                        // if(newData["completed_consent"] == 0) {
                        //   newData["completed_consent"] = true;
                        // } else if(newData["completed_consent"] == 1) {
                        //   newData["completed_consent"] = false;
                        // }

                        // if(newData["sms_opt_out"] == 0) {
                        //   newData["sms_opt_out"] = true;
                        // } else if(newData["sms_opt_out"] == 1) {
                        //   newData["sms_opt_out"] = false;
                        // }
                        
                        console.log("onRowUpdate PROCESSED newData is ", newData)

                        //Pass for updating
                        cbRequest.updateDataFor(props.collection, newData, tid). then(() => {
                            //this.loadTableWith(null);
                            //this.setState([...this.state.data, dataUpdate]);
                            //this.setState({...this.state,  data: dataUpdate});
                            resolve();
                        });

                        
                      }),
                    onRowDelete: oldData =>
                      new Promise(async (resolve, reject) => {

                        //Handle configuration table 
                        if (props.collection == "config") {
                            if (oldData.index == "clients" || oldData.index == "pilot" || oldData.index == "d2c") {
                                window.alert("This collection can not be removed.");
                                reject()
                                return;
                            }
                            //Remove site from config
                            await cbFirestore.deleteSiteFromConfig(oldData.topLevelCollections);
                            await cbFirestore.deleteSiteFromTopLevel(oldData.topLevelCollections)
                            resolve();
                            return;
                        }

                        //Pass for removal
                        cbRequest.deleteDataFor(props.collection,oldData.index, tid, () => {
                            //this.loadTableWith(null,() => {});
                            
                        })
                        resolve();
                       // return true;
                      }),
                  }}
            />
        </div>
      )
}

export default FirebaseDatatableRequest;