/*
    CBNotFound

    4/10/22
    PURPOSE

    Common 404 page

*/

import React from 'react';
import {
    useHistory
  } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  
  export default function CBNotFound() {
    const classes = useStyles();
    const history = useHistory();
  
    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={4} />
            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Page not found
                </Typography>
                <Typography variant="h5" component="h2">
                    404
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    Page does not exist or you don't have credentials to view
                </Typography>
                <Typography variant="body2" component="p">
                </Typography>
                </CardContent>
                <CardActions>
                <Button size="small" onClick={history.goBack}>Go Back</Button>
                </CardActions>
            </Card>
            <Grid item xs={4} />
        </Grid>
    );
  }


