import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import TextField from '@material-ui/core/TextField';

import Chat from './SMSChat.js';
import cbRequest from "./CBRequest.js";
import MommiChat from "./SMSChat.js";
import { stubTrue } from "lodash";

const _ = require("underscore");
const moment = require("moment");
/*


*/
//export default function CBTextingModal( { modalTitle, modalIndex, showModal }) {
// function CBTextingModal( { modal }) {
//     //const [show, setShow] = React.useState();
//     const [open, setOpen] = React.useState(modal.showModal);
//     const [textHistory, setTextHistory] = React.useState(modal.textHistory);
//     const [scroll, setScroll] = React.useState('paper');
//     const [value, setValue] = React.useState("");
    
//     const handleClickOpen = () => () => {
//         setOpen(true);
//         //setScroll(scrollType);
//     };
    
//     const handleClose = () => {
//         setOpen(false);
//         modal.showModal = false;
//     };

//     const handleSendText = (msg, cb) => {
//         console.log("handleSendText ", msg)
//         //Shouldn't happen
//         if (modal.id == null || modal.id.length == 0 ) { return ;}
//         if (modal.phone == null || modal.phone.length == 0 ) { return ;}
//         //Pass this off to cbRequest
//         cbRequest.sendTextTo(modal.id, modal.phone, msg, (resp) => {
//             cb(resp);
//         });
//     } 

//     const handleChange = (event) => {
//         setValue(event.target.value);
//     };
    
//     const handleLoadTextHistory = (index) => {
//         //no index, no mother, no text history
//         if (index == null) { return []; }

//         //make request for text history from backend
//         return ( modal.textHistory != null) ? modal.textHistory : [];
//     }

//     const descriptionElementRef = React.useRef(null);
//     React.useEffect(() => {
//         if (open) {
//             const { current: descriptionElement } = descriptionElementRef;
//             if (descriptionElement !== null) {
//                 descriptionElement.focus();
//             }
//         }
//     }, [open]);

//     //Update the open state of the modal when modal.showModal is modified
//     React.useEffect(() => {
//         //only change if needed
//         if ( open != modal.showModal) { 
//             setOpen(modal.showModal)
//         }

//         if ( textHistory != null && modal.textHistory != null && (textHistory.length != modal.textHistory.length) ) {
//             setTextHistory(modal.textHistory)
//         }
//         console.log("useEffect on TextingModal")
       
//     }, [modal]);


//     //Read in text history for this mother

//     return (
//         <div>
//             {/* <Button onClick={handleClickOpen()}>Show Modal</Button> */}
//             <Dialog
//             open={open}
//             onClose={handleClose}
//             scroll={scroll}
//             fullWidth={true}
//             maxWidth={"lg"}
//             aria-labelledby="scroll-dialog-title"
//             aria-describedby="scroll-dialog-description"
//             >
//             <DialogTitle id="scroll-dialog-title">{modal.title} Text History</DialogTitle>
//             <DialogContent dividers={scroll === 'paper'}>
//                 <DialogContentText
//                 id="scroll-dialog-description"
//                 ref={descriptionElementRef}
//                 tabIndex={-1}
//                 >
//                     {/* Pass chat history */}
//                     {/* <Chat textHistory={handleLoadTextHistory(modal.id)} onSend={handleSendText}/> */}
//                     {/* <Chat textHistory={textHistory} onSend={handleSendText}/> */}
//                     <MommiChat textHistory={textHistory} onSend={handleSendText}/>  
//                 </DialogContentText>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleClose} color="primary">
//                 Cancel
//                 </Button>
//             </DialogActions>
//             </Dialog>
//         </div>
//     );

// }


class MommiTextingModal extends React.Component {

    constructor(props) {
        super(props);
        console.log("MommiTextingModal props ", props )
        //Get the top level collection indicated by the path name
        const pathParts = window.location.pathname.split("/");
        const tid = pathParts[1];
        this.state = {
            open : props.modal.showModal,
            textHistory : props.modal.textHistory,
            scroll : 'paper',
            value : "",
            title : props.modal.title,
            id : props.modal.id,
            phone : props.modal.phone,
            tid : tid,
            showModal : false,
            disableSMS: props.modal.disableSMS
        }

        // const [open, setOpen] = React.useState(modal.showModal);
        // const [textHistory, setTextHistory] = React.useState(modal.textHistory);
        // const [scroll, setScroll] = React.useState('paper');
        // const [value, setValue] = React.useState("");
    }
    shouldComponentUpdate( nextProps ) {
        console.log("shouldComponentUpdate ?", nextProps.modal.triggerAppearance !== this.state.triggerAppearance)
        // if (nextProps.modal.triggerAppearance !== this.state.triggerAppearance) {
        //     this.setState({
        //         ...this.state,
        //         open : !this.state.open
        //     })
        // }
        //return nextProps.modal.triggerAppearance !== this.state.triggerAppearance;
        return true;;
    }

    static getDerivedStateFromProps(props, state) { 
        console.log("MommiTextingModal getDerivedStateFromProps  props: ", props , " state: ",  state)
        const p = Object.assign({}, props.modal);
        
        if ('menuOpen' in props.modal && props.modal.menuOpen) {
            p.open = false; p.showModal = false;
            return p;
        }

        
        //IF the modal hasn't been updated with text history, no update
        if ( state.id.length == 0  ){

            //Has props been updated?
            if (state.hasOwnProperty("triggerAppearance") && _.isBoolean(state.triggerAppearance) ) {
                p.open = state.triggerAppearance
                p.showModal = p.open;
                return p;
            }
            else if (props.modal.id.length > 0 && props.modal.triggerAppearance){
                p.open = true;
                p.showModal = true;
                
                return p;
            }
            return null;
        }
        else {
            p.open = state.menuOpen ? false : state.triggerAppearance;
            p.showModal = p.open;
            return p
        }
    }
    // static getDerivedStateFromProps(props, state) {
    //     console.log("MommiTextingModal getDerivedStateFromProps  props: ", props , " state: ",  state)
    //     const p = Object.assign({}, props.modal);
    //     delete p.triggerAppearance;
        // if ('showModal' in props.modal ) { 
        // if ('showModal' in props.modal && props.modal.showModal != state.showModal) { 
        //     // p.open = state.showModal ?  true : false;
        //     //p.open = !p.open;
        //     p.open = p.showModal;
        // }
        // p.showModal = props.modal.show
        // 1. Initial load - prop.modal init, state init
        // 2. Show modal - prop.modal change, state - 
        // 3. Close modal - prop.modal - , state change
        // if ( !props.modal.showModal && !state.open) {
        //     p.open = false;
        //     p.showModal = false;
        //     //return null;
        // }
        //if state is empty refer to propps
        // if (props.modal.triggerAppearance) {
        //     p.open = props.modal.triggerAppearance;
        //     p.showModal = p.open;
        //     p.triggerAppearance = false;

            
        // }
        // else if (props.modal.triggerAppearance !== state.triggerAppearance) {
        //     p.open = !p.open;
        //     p.showModal = p.open;
        // }
        // else {
        //     p.open = state.open;
        //     p.showModal = p.open;
        // }

        // if ( props.modal.hasOwnProperty('closeMe')) {
        //     props.modal.closeMe();
        // }
        // p.open = props.modal.triggerAppearance
        // p.showModal = p.open;

        // if ( !state.showModal) {
        //     p.open = false;
        //     p.showModal = p.open;
        // }
        // if ( state.hasOwnProperty("triggerAppearance") ){
        //     p.open = state.open;
        //     p.showModal = p.open;
        // }
        // else {
        //     p.open = props.modal.triggerAppearance;
        //     p.showModal = p.open;
        // }
        // if ( !_.isUndefined(state.open)) {
        //     p.open = state.open;
        // }
        // else if (props.modal.triggerAppearance ) {
        //     p.open = props.modal.triggerAppearance;
        // }
        // else {
        //     p.open = true;
        // }
        // p.showModal = p.open;
        // return p;

        // var checkState = true;
        // // Handle the cases where no action is OK
        // if ('triggerAppearance' in props.modal == false && !state.open && !state.showModal) {
        //     console.log("no action needed")
        //     return null;
        // }
        // else if (props.modal.triggerAppearance) {
        //     p.open = true;
        //     p.showModal = true;
        //     p.triggerAppearance = false;
        //     checkState = false;
        // }
        // else {
        //     p.open = props.modal.showModal;
        //     checkState = false;
        // }

        // if (checkState) {
        //     if ( !state.showModal) {
        //         p.open = false;
        //         p.showModal = false;
        //     }
        //     else if (state.showModal )  {
        //         p.open = true;
        //         p.showModal = true;
        //     }
        //     //override
        //     if (!p.triggerAppearance) {
        //         p.open = false;
        //     }
        // }
      
        // //if the sidemenu is open, make sure to not show the moda
        // // else if (props.menuOpen){
        // if (props.modal.menuOpen){
        //     p.open = false;
        //     p.showModal = false;
        //     return null;
        // }
        // else if (props.modal.showModal) {
        //     p.open = true;
        //     p.showModal = true;
        // }
        // // else if ( props.modal.showModal && !props.menuOpen) {
        // //     //check if the title has changed
        // //     //.open = (props.modal.title != state.title || moment().format("MM-DD-YYYY_HH:mm:ss") != state.lastUpdate) ? true : false;
        // //     //p.open = !state.open;
        // //     p.open = props.modal.showModal;
        // //     p.showModal = p.open;
        // // }
        // else {
        //     p.open = state.showModal;
        //     p.showModal = p.open;
        // }
        
        // console.log(p);
        // console.log("checkState: ", checkState)
        // return p;
    // }

    componentDidMount() {
        console.log("MommiTextingModal componentDidMount")
    }

    render(){
        const descriptionElementRef = React.createRef(null);

        const handleSendText = (msg, cb) => {
            console.log("handleSendText ", msg)
            //Shouldn't happen
            if (this.state.id == null || this.state.id.length == 0 ) { return ;}
            if (this.state.phone == null || this.state.phone.length == 0 ) { return ;}
            //Pass this off to cbRequest  sendTextTo : (phone, msg, tid, callback) => {
            cbRequest.sendTextTo(this.state.phone, msg, this.state.tid, (resp) => {
                cb(resp);
            });
        }
        return (
            <div>
                {/* <Button onClick={handleClickOpen()}>Show Modal</Button> */}
                <Dialog
                open={this.state.open}
                onClose={ () => {
                    this.setState({ ...this.state, open : false, triggerAppearance : false, id : "" });
                }}
                scroll={this.state.scroll}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                >
                <DialogTitle id="scroll-dialog-title">{this.state.title} Text History</DialogTitle>
                <DialogContent dividers={this.state.scroll === 'paper'}>
                    <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    ></DialogContentText>
                        {/* Pass chat history */}
                        {/* <Chat textHistory={handleLoadTextHistory(modal.id)} onSend={handleSendText}/> */}
                        {/* <Chat textHistory={textHistory} onSend={handleSendText}/> */}
                        <MommiChat disableSMS={this.state.disableSMS} textHistory={this.state.textHistory} onSend={handleSendText}/>  
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => {
                        this.setState({ ...this.state, 
                            open : false,
                            triggerAppearance : false,
                            id : ""
                        });
                    }} color="primary">
                    Cancel
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        )
    }
}

//
export default MommiTextingModal;