// export const firebaseConfig = {
//     apiKey: "AIzaSyBHsfj2VO7hSRVqXaHFVZaQJTdZak_i8sY",
//     authDomain: "chatbot-e9330.firebaseapp.com",
//     projectId: "chatbot-e9330",
//     storageBucket: "chatbot-e9330.appspot.com",
//     messagingSenderId: "39657881815",
//     appId: "1:39657881815:web:9953b9668a421557237092",
//     measurementId: "G-W9W7G7DV9X"
// };
export const theFirebaseConfig = {
    prodFirebaseConfig : {
        apiKey: "AIzaSyBHsfj2VO7hSRVqXaHFVZaQJTdZak_i8sY",
        authDomain: "chatbot-e9330.firebaseapp.com",
        projectId: "chatbot-e9330",
        storageBucket: "chatbot-e9330.appspot.com",
        messagingSenderId: "39657881815",
        appId: "1:39657881815:web:9953b9668a421557237092",
        measurementId: "G-W9W7G7DV9X"
    },
    stagingFirebaseConfig : {
        apiKey: "AIzaSyCTyXKnUhGFg3fkQtJy_HqZCy872p_E5Dw",
        authDomain: "mommi-dev.firebaseapp.com",
        projectId: "mommi-dev",
        storageBucket: "mommi-dev.appspot.com",
        messagingSenderId: "371963632370",
        appId: "1:371963632370:web:d496661907b222f4b58e6b",
        measurementId: "G-PRZNR0TG5E"
      }
}