import React from 'react';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = (theme) => ({
  exportForm: {
    display: 'flex',
    'flex-direction': 'column',
    gap: '15px'
  },
  backDrop: {
    zIndex: 1000,
    color: '#fff'
  }
});
const endpointURL = window._env_.API_URL + 'sms/export-messages';

class ExportReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromValue : moment(),
      toValue : moment(),
      openLoader: false
    }

    // bind this state to handlSubmit
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loaderOpen = this.loaderOpen.bind(this);
    this.loaderClose = this.loaderClose.bind(this);
  }

  loaderOpen() {
    this.setState({ openLoader: true });
  }

  loaderClose() {
    this.setState({ openLoader: false });
  }

  toCSVString(jsonObj) {
    let replacer = (key, value) => value === null ? '' : value;
    let header = Object.keys(jsonObj[0]);
    let csv = [
        header.join(','),
        ...jsonObj.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')),
    ].join('\r\n');
    return csv;
  }

  downloadCSV(content, contentType) {
    let blob = new Blob([content], { type: contentType });
    let link = document.createElement('a');
    let fileName = `message-export_${moment().format("YYYY-MM-DD")}.csv`;
    let blobUrl = URL.createObjectURL(blob);
    link.setAttribute('href', blobUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.loaderOpen();
    try {
      let body = JSON.stringify({
        fromDate: this.state.fromValue.format("YYYY-MM-DD"),
        toDate: this.state.toValue.format("YYYY-MM-DD")
      });
      let res = await fetch(endpointURL, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body
      });
      let resJson = await res.json();
      if (res.status === 200) {
        this.loaderClose();
        let csvString = this.toCSVString(resJson);
        this.downloadCSV(csvString, "data:text/csv;charset=utf-8")
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes, theme } = this.props;
    return(
      <div>
        <h1>Export Messages</h1>
        <form className={classes.exportForm} onSubmit={this.handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker 
            label="From Date"
            value={this.state.fromValue}
            onChange={(newValue) => {
              this.setState({ fromValue: newValue });
            }}
            renderInput={(params) => <TextField {...params}></TextField>}
            ></DatePicker>
            <DatePicker 
            label="To Date"
            value={this.state.toValue}
            onChange={(newValue) => {
              this.setState({ toValue: newValue });
            }}
            renderInput={(params) => <TextField {...params}></TextField>}
            ></DatePicker> 
          </LocalizationProvider>
          <Button variant="contained" type="submit">Export Report</Button>
        </form>
        <Backdrop 
          className={classes.backDrop}
          open={this.state.openLoader}
          onClick={this.loaderClose}
        >
          <CircularProgress color="secondary">Loading</CircularProgress>
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(useStyles, {withTheme:true}) (ExportReport)