import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import WcIcon from '@material-ui/icons/Wc';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DescriptionIcon from '@material-ui/icons/Description';
import Button from '@material-ui/core/Button';
import FileDownloadIcon from '@material-ui/icons/ImportExport'
import CBTable from "./CBTable";
import FirebaseDatatableRequest from "./FirebaseDatatableRequest.js";
import cbRequest from "./CBRequest.js";
import ExportReport from './ExportReport';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FirebaseSetup from "./FirebaseSetup";
import firebase from "firebase/app";
import CBFirestore from './CBFirestore';
import CBNotFound from './CBNotFound';

const drawerWidth = 240;

//const useStyles = makeStyles((theme) => ({
const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
});



class MommiPersistentDrawerLeft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryLinks : [{
        url : "/consultants",
        title : "Consultants",
        collection : "consultants",
        listIcon : <PermContactCalendarIcon />
      },{
        url : "/mothers/all",
        title : "Mothers",
        collection : "mothers",
        listIcon : <PregnantWomanIcon />
      }],
      secondaryLinks : [
      {
        url : "/analytics",
        title : "Analytics",
        collection : "analytics",
        listIcon : <InsertChartIcon /> 
      },
      {
        url : "/sms-templates",
        title : "SMS Templates",
        collection : "automation",
        listIcon : <DescriptionIcon />
      },
      {
        url : "/export-messages",
        title : "Export Messages",
        collection : "mothers",
        listIcon : <FileDownloadIcon />
      }],
      //classes : classes(),
      siteName : "",
      isLoggedIn : false,
      open : false,
      isAdmin : false,
      isCoach : false,
      isValid : false,
      tid : ""
    }

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleSignInStatus = this.handleSignInStatus.bind(this);
    this.handleSignout = this.handleSignout.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSigningOut = this.handleSigningOut.bind(this);
  }


  componentDidMount() {

    ( async () => {
      
      try  {
        const parts = window.location.pathname.split("/");
        var isValid = false;
        var isCoach = false;
        
        
        const isAdmin = await cbRequest.isAdmin(parts[1]);
        console.log("isAdmin is ",isAdmin)

        // to check if coach or any non admin is trying to access a toplevel collection which does not exist in the FireStore config under main doc clients
        if ( parts.length > 1 &&  parts[1].length>0){
          // Obtain if this top level collection is valid
          isValid = await CBFirestore.verifyTopLevelCollection(parts[1])
          console.log(parts);
          if (isValid) {
            isCoach = await cbRequest.isCoach(parts[1]);
            console.log("isCoach is " + isCoach)
           // isCoach = isCoach.result;
          }
          // console.log("verifyTopLevelCollection:",isValid)
          // this.setState( {...this.state, isValid : isValid })
        }
        // const isAdmin = await cbRequest.isAdmin()
        // Obtain the site name 
        await cbRequest.getSiteName( parts[1], (siteName) => {
          const pprimaryLinks = this.state.primaryLinks;
          //Insert Hospitals only with the pilot collection
          if (parts[1] == "clients") {
            pprimaryLinks.splice(0,0,{
              url : "/hospitals",
              title : "Hospitals",
              collection : "config",
              listIcon : <ApartmentIcon />
            })
          }
          //Update, site name, if user is an admin, if the TL is valid and the top level id
          this.setState( {...this.state, 
            primaryLinks : pprimaryLinks,
            siteName : siteName, 
            isAdmin : isAdmin, 
            isValid : isValid, 
            isCoach : isCoach,
            tid: parts[1]
          });

          //handle the sign in status again
          //this.handleSignInStatus(this.state.isLoggedIn);
        })
      }
      catch (e) {
  
      } 
      console.log("PersistentDrawerLeft componentDidMount ", this.props)
    })()
  }
  
  componentDidUpdate() {
    // cbRequest.getSiteName( (siteName) => {
    //   // this.setState( {...this.state, siteName : siteName, isAdmin : isAdmin});
    //   this.setState( {...this.state, siteName : siteName});
    // })
    
    console.log("PersistentDrawerLeft componentDidUpdate")
  }
  handleClick() {

  }
  handleDrawerOpen() {
    this.setState({ ...this.state, open : true})
  }

  handleDrawerClose() {
    this.setState({ ...this.state, open : false})
  }

  handleSignInStatus(isLoggedIn) {
    this.setState({...this.state, isLoggedIn : isLoggedIn})
    console.log("handleSignInStatus")
    const parts = window.location.pathname.split("/");
    //const c = (this.state.tid == "") ? "d2c" : this.state.tid;
    
    //Redirect to Mothers once logged in
    //..default to d2c 
    if (window.location.pathname == "/" || parts.length == 1) {
      window.location.pathname = "/clients/mothers/all"
    }
    else if (parts.length == 2){
      window.location.pathname += "/mothers/all";
    }

  }

  handleSignout() {
    console.log("Sign out user")
    //setIsSigingOut(true)
    firebase.auth().signOut();
    cbRequest.setUser(null);
  }

  handleSigningOut() {

  }
  
  render() {
    
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
      
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
            {this.state.siteName}
            </Typography>
              { this.state.isLoggedIn ? <Button variant="outlined" color="inherit" style={{ marginLeft: "auto"}} onClick={this.handleSignout} href="/"> Sign Out </Button> : null }
          </Toolbar>
        </AppBar>

        <Router>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={this.state.open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              {this.state.primaryLinks.map( (value, index) => {
                const pp = "/"+this.state.tid + value.url;
                return <ListItem button key={value.title}>
                  <ListItemIcon> {value.listIcon}</ListItemIcon>
                  <Link to={{ pathname : pp, state : "refresh"}} onClick={this.handleClick}>
                    <ListItemText primary={value.title} />
                  </Link>
                </ListItem>
              })}
            </List>
            <Divider />
            <List>
            {this.state.secondaryLinks.map( (value, index) => {
              const pp = "/"+this.state.tid + value.url;
                return <ListItem button key={value.title}>
                  <ListItemIcon> {value.listIcon}</ListItemIcon>
                  <Link to={{ pathname : pp, state : "refresh"}} onClick={this.handleClick}>
                    <ListItemText primary={value.title} />
                  </Link>
                </ListItem>
              })}
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: this.state.open,
            })}>

            <div className={classes.drawerHeader} />
            <div className={classes.root}>
              <Switch>
                
                {/* add content for each page in the admin dashboard*/}
                {this.state.primaryLinks.concat(this.state.secondaryLinks).map((value, index) => {
                  const htmlId = "cbLink-"+index;      
                  
                  
                  //Handle direct route to mother's text history 
                  if (value.url.includes("mothers")) {
                    return <Route path="/:tid/mothers/:mid" key={htmlId}>
                              {/* Show login, if needed*/}
                            { !this.state.isLoggedIn ? 
                                <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={3} />
                                    <Grid item xs style={ {textAlign: "center"} }>
                                        <Paper variant="outlined" >
                                          <FirebaseSetup signOutHandler={this.handleSignInStatus} checkIfSigningOut={this.handleSigningOut}  />
                                        </Paper>
                                    </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                                :

                                !this.state.isAdmin && this.state.tid == "pilot" ? 
                                <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={3} />
                                    <Grid item xs style={ {textAlign: "center"} }>
                                        <Paper variant="outlined" >
                                          <p> You are not authorized</p>
                                        </Paper>
                                    </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                                :
                                  <div style={{width:"100%"}}>
                                    {/* <CBTable id={htmlId} title={value.title} collection={value.collection}  /> */}
                                    <FirebaseDatatableRequest title={value.title} collection={value.collection} menuOpen={this.state.open} isAdmin={this.state.isAdmin} isCoach={this.state.isCoach} />
                                  </div> 
                            }
                    </Route>
                  }
                  //Handle route for hospitals
                  else if (value.url.includes("hospitals")) {

                    // var entries = [];
                    // if (this.state.isAdmin) {

                    // }

                    // return <Route exact path={value.url} key={htmlId}>
                    return <Route exact path="/:tid/hospitals" key={htmlId}>
                      { this.state.isAdmin  ? <Grid container className={classes.root} spacing={2}>
                                                <Grid item xs={6} >
                                                    <Paper variant='outlined' > 
                                                      Create new clinics or hospitals that will be accessible via <strong>https://admin.mommi.us/&lt;name&gt;</strong><br/>
                                                      Collections <strong>can only be deleted</strong>, not modified.<br/>
                                                      Collection <strong>d2c, pilot </strong> can not be deleted.
                                                    </Paper>
                                                </Grid>
                                    <div style={{width:"100%"}}>
                                      {/* <CBTable id={htmlId} title={value.title} collection={value.collection}  /> */}
                                      <FirebaseDatatableRequest title="Collections" collection="config" menuOpen={this.state.open} isAdmin={this.state.isAdmin} isCoach={this.state.isCoach}  />
                                    </div> 
                                  <Grid item xs={3} />
                                </Grid> : <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={3} />
                                    <Grid item xs style={ {textAlign: "center"} }>
                                        <Paper variant="outlined" >
                                          <p> You are not authorized</p>
                                        </Paper>
                                    </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                    }
                    </Route>
                  }
                  else if (value.url.includes("export-messages")) {
                    return <Route exact path={"/:tid/"+"export-messages"} key={htmlId}>
                      <ExportReport></ExportReport>
                    </Route>
                  }
                  return <Route exact path={"/:tid"+value.url} key={htmlId}>
                            {/* Show login, if needed*/}
                            { !this.state.isLoggedIn ? 
                                <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={3} />
                                    <Grid item xs style={ {textAlign: "center"} }>
                                        <Paper variant="outlined" >
                                          <FirebaseSetup signOutHandler={this.handleSignInStatus} checkIfSigningOut={this.handleSigningOut}  />
                                        </Paper>
                                    </Grid>
                                  <Grid item xs={3} />
                                </Grid>
                                :
                                  <div style={{width:"100%"}}>
                                    {/* <CBTable id={htmlId} title={value.title} collection={value.collection}  /> */}
                                    <FirebaseDatatableRequest title={value.title} collection={value.collection} menuOpen={this.state.open} isAdmin={this.state.isAdmin} isCoach={this.state.isCoach} />
                                  </div> 
                            } 
                          </Route>
                    
                })}
                
                
                {/* Route not found
                { _.isNull(firebase.auth().currentUser) ? <Route component={CBNotFound} /> 
                : */}
                  {/* default login prompt*/}
                  <Route path="/">
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={3} />
                      {/* Show login, if needed*/}
                      { !this.state.isLoggedIn ? 
                        <Grid item xs style={ {textAlign: "center"} }>
                            <Paper variant="outlined" >
                              <FirebaseSetup signOutHandler={this.handleSignInStatus} checkIfSigningOut={this.handleSigningOut}  />
                            </Paper>
                        </Grid>
                        : null }
                      <Grid item xs={3} />
                    </Grid>
                  </Route>

              </Switch>
            </div>
          </main>
        </Router>
      </div>

    );
  }
}

// export default withTheme(MommiPersistentDrawerLeft)
export default withStyles(useStyles, {withTheme:true}) (MommiPersistentDrawerLeft)