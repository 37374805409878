/*

    Interfaces with Firestore Database

*/

import firebase from "firebase/app";
import { resolve } from "url";
// import firestore, { Firestore } from "@google-cloud/firestore";

// Required for side-effects
require("firebase/firestore");
const _ = require("underscore");
const moment = require("moment");
const $ = require("jquery");

var docMap = {};

const CBFirestore = {

    getFirestore : () => {
        const db = firebase.default.firestore();
        return db;
    },
    pilotCollections : () => {
        return ["coaches","automation","config","consultants","hospitals","mothers","sms_templates","users"];
    },
    //Obtain all top level collections by referencing the config collection
    getTopLevelCollections : async () => {
        return new Promise(async (resolve, reject) => {
            const db = CBFirestore.getFirestore();
            const configPath = await CBFirestore.createPathTo("config");
            const config = await db.collection(configPath).get()
            const configDocs = config.docs.map( doc => doc.id);
            if (configDocs.length == 1) {
                const configDoc = await db.collection(configPath).doc(configDocs[0]).where("topLevelCollections").get()
                const d = configDoc.data();
                const o = {};
                o[configDocs[0]] = d;
                resolve(o);
            }   
        })
         

    },
    verifyTopLevelCollection : async (name) => {

        return new Promise(async (resolve,reject)=> {
            const pDocMap = await CBFirestore.extractDocMap("clients");
            const sConfigPath = await CBFirestore.createPathTo("config","clients", pDocMap);
            console.log("in verifyTopLevelCollection")
            const db = CBFirestore.getFirestore();
            const configColl = await db.collection(sConfigPath).get();
            const configDocs = configColl.docs.map( doc => doc.data() );
            console.log(configDocs)
            if (configDocs.length == 1) {
                const topCols = _.keys(configDocs[0].topLevelCollections);
                console.log(topCols)
                resolve( (topCols.indexOf(name) != -1) ? true : false);
            }
        })
        
    },
    addNewSiteToConfig : async (name,site_name) => {
        //Update the pilot 
        //get the docMap for the pilot collection
        const pDocMap = await CBFirestore.extractDocMap("clients");
        const sConfigPath = await CBFirestore.createPathTo("config","clients", pDocMap);

        const db = CBFirestore.getFirestore();
        const configColl = await db.collection(sConfigPath).get();
        const configDocs = configColl.docs.map( doc => doc.id);

        if (configDocs.length == 1) {
            // db.collection(sConfigPath).doc(configDocs[0]).update({
            //     topLevelCollections : firebase.firestore.FieldValue.arrayUnion(name)
            // });
            const up = {
                topLevelCollections : {}
            };
            up["topLevelCollections"][name] = site_name;
            // console.log(up)
            db.collection(sConfigPath).doc(configDocs[0]).set(up, {merge : true});
        }
    },
    deleteSiteFromConfig: async (name) => {
        //Update the pilot 
        //get the docMap for the pilot collection
        const pDocMap = await CBFirestore.extractDocMap("clients");
        const sConfigPath = await CBFirestore.createPathTo("config","clients", pDocMap);

        const db = CBFirestore.getFirestore();
        const configColl = await db.collection(sConfigPath).get();
        const configDocs = configColl.docs.map( doc => doc.id);

        if (configDocs.length == 1) {
            db.collection(sConfigPath).doc(configDocs[0]).set( { topLevelCollections : {
                [name] : firebase.firestore.FieldValue.delete()
            }}, {merge : true});
        }
    },
    deleteSiteFromTopLevel : async (name) => {

        return new Promise( async (resolve, reject) => {          
            //Obtain the top level collection; create a bulk writer to perform recursive deletion
            const db = CBFirestore.getFirestore();
            const docMap = await CBFirestore.extractDocMap(name);
            _.map(docMap, async (index, col) => {
                //get sub collection reference
                const p = "/" + name + "/" + index + "/" + col;
                
                const subColRef =  await db.collection(p).get();
                await Promise.all(subColRef.docs.map( async (doc) => {
                    // console.log(p,doc.id)
                    await db.collection(p).doc(doc.id).delete()
                }));
            })
            const firstLevelDocs = await db.collection(name).get();
            await Promise.all(firstLevelDocs.docs.map(async (doc) => {
                db.collection(name).doc(doc.id).delete();
            }));
            resolve();
   
        })

        
    },
    createNewTopLevelCollection : async (name) => {
        // pilotCollections.forEach(async (c) => {
        //   await db.collection(name).doc().collection(c).doc()
        // })
        const db = CBFirestore.getFirestore();
       
        await Promise.all(CBFirestore.pilotCollections().map( (c) => {
            return new Promise( async (resolve, reject) => {
            try {
                const subColRef = await db.collection(name);
                //add a document under the top level
                const subDocRef = await subColRef.add({})
                //create the subcollection and empty doc
                await db.collection(name).doc(subDocRef.id).collection(c).doc().set({});
                resolve("created!")
            }
            catch (e) {
                console.log("createNewTopLevelCollection error: ", e)
                reject(e)
            }
            })
        }))
        
    },
    setupFromPilotTo : async(dest_collection) => {
        //get the docMap for the pilot collection
        const pDocMap = await CBFirestore.extractDocMap("clients");
        const sConfigPath = await CBFirestore.createPathTo("config","clients", pDocMap);
        const sAutomationPath = await CBFirestore.createPathTo("automation","clients", pDocMap);
        //Destination collection should exist by now
        const dDocMap = await CBFirestore.extractDocMap(dest_collection);
        const dConfigPath = await CBFirestore.createPathTo("config",dest_collection, dDocMap);
        const dAutomationPath = await CBFirestore.createPathTo("automation",dest_collection, dDocMap);
        //Perform copy
        await CBFirestore.copyCollectionTo(sConfigPath, dConfigPath);
        await CBFirestore.copyCollectionTo(sAutomationPath, dAutomationPath);
    },
    // Copies a given collection into a new one
    copyCollectionTo : async (srcCollectionName, destCollectionName) => {

        return new Promise( async (resolve, reject) => {
            const db = CBFirestore.getFirestore();
            console.log(srcCollectionName, destCollectionName)
            const documents = await db.collection(srcCollectionName).get();
            let writeBatch = db.batch();
            const destCollection = db.collection(destCollectionName);
            let i = 0;
            for (const doc of documents.docs) {
                writeBatch.set(destCollection.doc(doc.id), doc.data());
                i++;
                if (i > 400) {  // write batch only allows maximum 500 writes per batch
                    i = 0;
                    console.log('Intermediate committing of batch operation');
                    await writeBatch.commit();
                    writeBatch = db.batch();
                }
            }
            if (i > 0) {
                console.log('Firebase batch operation completed. Doing final committing of batch operation.');
                await writeBatch.commit();
                resolve();
            } else {
                console.log('Firebase batch operation completed.');
                resolve();
            }
        })
        
    },
    // Determine system collection app uses
    getTopLevelCollection : () => { 
        // return (!_.isUndefined(window._env_.API_URL) && window._env_.API_URL == "https://mommi-dev.uc.r.appspot.com/" ) ? "clients" : "d2c";

        return "clients";
    },
    extractDocMap : async (topC = -1) => {

        return new Promise( async (resolve, reject) => {
            const pilotCollections = CBFirestore.pilotCollections();
            const db = CBFirestore.getFirestore();
    
            //safe to obtain reference to pilot
            const pilotRef = db.collection((topC == -1 ) ? CBFirestore.getTopLevelCollection() : topC);
    
            // Check if expected collections
            const pilotDocuments = await pilotRef.get();            
    
            //obtain all the docIds 
            const docIds = pilotDocuments.docs.map( doc => doc.id);
            // console.log(docIds)
            //clear the docmap
            docMap = {};
            //Loop through each document
            const docToCols = await Promise.all(_.map(docIds, (d_id) => {
    
                return new Promise((resolve2,reject2) => {
                    // Loop through each expected document type
                    pilotCollections.forEach( async (c) => {
    
                        try {
                        
                            const subCol = await pilotRef.doc(d_id).collection(c).get()
                            
                            if (subCol.docs.length > 0)  {
                                resolve2({
                                    id: d_id,
                                    c : c
                                })
                            }
                            
                        }
                        catch (e) {
                            console.log(e)
                        }
                    })
                })
    
            }))
            docToCols.forEach( (o) => {
                // docMap[o.id] = o.c
                docMap[o.c] = o.id;
            })
            // console.log(docMap);
            resolve(docMap);
        })
       
    },
    createPathTo : (tbl , topL = CBFirestore.getTopLevelCollection(), dm = -1) => {
       
        return new Promise(async ( resolve, reject) =>{
            // const topL = CBFirestore.getTopLevelCollection();
            const docMap = (dm == -1) ? await CBFirestore.extractDocMap() : dm;
            if (tbl in docMap) {
                resolve( "/" + topL + "/" + docMap[tbl] + "/" + tbl);
            }
        })
        
    }
}

// exports.cbFirestore = CBFirestore;
export default CBFirestore;