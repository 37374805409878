import React, { Component, useEffect } from "react";
// import {
// BrowserRouter as Router,
// Switch,
// Route,
// Link
// } from "react-router-dom";
// import Grid from '@material-ui/core/Grid';
// //import FirebaseSetup from "./FirebaseSetup";
// import Paper from '@material-ui/core/Paper';
// import DataTable from 'react-data-table-component';
// import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
//import MaterialTable from "material-table";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Send from '@material-ui/icons/Send';
import Sms from '@material-ui/icons/Sms';
import Refresh from '@material-ui/icons/Refresh';
import ViewColumn from '@material-ui/icons/ViewColumn';
import EventIcon from '@material-ui/icons/Event';

//import CBTextingModal  from "./TextingModal";
import MommiTextingModal  from "./TextingModal";
import cbRequest from "./CBRequest.js";
//import SmsBadgeIcon from "./SmsBadgeIcon.js";
//import firebase from "firebase/app";
import Fuse from 'fuse.js'

//const firebase = require("firebase");
// Required for side-effects
//require("firebase/firestore");
const _ = require("underscore");
// Load the full build.
const _lodash = require('lodash');
const moment = require("moment");
//const Fuse = require('fuse.js')

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Send : forwardRef((props, ref) => <Send {...props} ref={ref} />),
    Sms : forwardRef((props, ref) => { console.log(props); return <Sms {...props} ref={ref} /> }),
    EventIcon : forwardRef((props, ref) => <EventIcon {...props} ref={ref} />),
    Refresh : forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
};

/*
  Renders all tables in the admin dashboard
*/
class CBTable extends Component {
    
    constructor(props) {
        super(props);
        
        //to handle unfulfilled promises
        //this.controller = new AbortController();
        this.asyncCallbackId = null;
        this.wrapper = React.createRef();
        this.rawData = [];
        this.tblCols = [];
        this.currentLink = null;
        this.lastDataPoll = null;

        this.state = {
            columns : [],
            data : [],
            currentLink : null,
            dataLength : 0,
            modal : {
                title : "", //Mother's name
                id : "",
                showModal : false,
                phone : "",
                textHistory : [],
                lastUpdate : moment().format("MM-DD-YYYY_HH:mm:ss")
            },            
            
        }

        
    }
    
    // static getDerivedStateFromProps(props, state) {
    //     console.log("getDerivedStateFromProps ", props)
    // }
    
    componentDidMount() {
        //
        console.log("CBTable componentDidMount")
        //Obtain the table columns
        this.loadTableColumns();
        // this.loadTableWith( null).then( (a) => {
        //     console.log("performLoad promise fulfilled")
        //     const dd = a[0];
        //     const currentLink = a[1];
        //     const cols = a[2];
        //     this.setState({ ...this.state, dataLength : dd.length, currentLink : currentLink, columns : cols});
        // });
        
        // this.asyncCallbackId = cbRequest.registerForDataCallback( () => {
        //     this.refreshTable();
        // })
        //establish relation
        // cbRequest.popTextHistory = (m_index) => {
        //     console.log("Load data for mom ", m_index)
        //     if (m_index == null) return;
            
        //     //find mom
        //     //const mom = _.filter(this.state.data, (o) => {
        //     const mom = _.filter(this.rawData, (o) => {    
        //         return o.index == m_index;
        //     })

        //     if (_.isNull(mom)) return;

        //     //update modal state
        //     this.setState({...this.state, modal : { 
        //         title : mom[0]["full_name"], 
        //         id : mom[0]["index"] , 
        //         phone : mom[0]["telephone"],
        //         textHistory : mom[0]["text_history"],
        //         showModal: true
        //     }});
        // }
    }

    // componentWillReceiveProps(nextProps) {
    //     console.log("CBTable componentWillReceiveProps ", nextProps);
    //     if (this.state.currentLink != null && this.state.currentLink == nextProps.id) { return;}
    
    //     //this.loadTableWith( nextProps);
        
    // }

    componentWillUnmount() {
        //this.controller.abort();
        console.log("CBTable componentWillUnmount");

        if (cbRequest.unsubscribe != null) {
            cbRequest.unsubscribe()
        } 

        if (this.asyncCallbackId) {
            cbRequest.removeDataCallback(this.asyncCallbackId);
        }
        delete cbRequest.popTextHistory;
    }

    componentDidUpdate(prevState, newState) {
        console.log("componentDidUpdate")
        //this.setState({ ...this.state, dataLength : this.rawData.length});
    }

    processRetrievedData(resp) {
        const tblData = (resp != null) ? resp : [];
        // Current table data
        //var this_d = this.state.data;
        var this_d = [];
        //Remove data 
        if ("removed" in tblData && tblData["removed"].length > 0 ) {
            //get the id's of data to remove
            const idsToRemove = _.map(tblData.removed, (o) => {
                return o.index;
            });

            //Get all data besides the ids to remove
            this_d = this_d.filter(item => idsToRemove.indexOf(item.index) == -1 );
        }
        //Add data
        if ("added" in tblData && tblData["added"].length > 0) {
            //ensure to only add different data
            //..get the indexs to add
            const idsToAdd =_.map(tblData.added, (o) => {
                return o.index;
            }); 
            //get list of current indices
            const currentIndices = _.map(this_d, (o) => {
                return o.index;
            })
            // check if new data doesn't exist in current indices
            const newDataIds = _.filter(idsToAdd, (o) => {
                return currentIndices.indexOf(o) == -1;
            })

            const dataToAdd = cbRequest.formatDataForDataTable(_.filter(tblData.added, (o) => {
                return newDataIds.indexOf(o.index) != -1;
            }));
            this_d = this_d.concat(dataToAdd);
        }
        if ("modified" in tblData && tblData["modified"].length > 0) {

            //this.loadTableWith(null); return;
            //get all the keys (index's) that have been modified
            const indexToM = {};
            const modKeys = _.map(tblData.modified, (o,i) => { 
                indexToM[o.index] = o;
                return o.index;
            });
            
            // find matches based on 'index'; filter out matching indices
            const updated = _.filter(_.map(this_d, (o,i) => {
                if ( 'index' in o && modKeys.indexOf(o.index) > -1 ) {
                    return i;
                }
                else{ 
                    return -1;
                }
            }), (o) => { return o > -1});
            console.log("Updated: ", updated)
            // updated.forEach( (x) => {
            //     this_d[x] = 
            // })
            //needs to just add this
            if (updated.length == 0) {
                const dataToAdd = cbRequest.formatDataForDataTable(tblData.modified);
                this_d = this_d.concat(dataToAdd);
            }
            else {
                // _.map(this_d, (o,i) => {
                //     if ( o.index in indexToM) {
                //         const m = _.extend(indexToM[o.index], o)
                //         this_d[i] = cbRequest.formatDataForDataTable([m])[0];
                //     }
                // })
                _.map(updated, (ix) => {
                    const m = _.extend(this_d[ix], indexToM[this_d[ix].index]);

                    // 1. Make a shallow copy of the items
                    let items = [...this_d];
                    // 2. Make a shallow copy of the item you want to mutate
                    //let item = {...items[ix]};
                    // 3. Replace the property you're intested in
                    //item.name = 'newName';
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    items[ix] = cbRequest.formatDataForDataTable([m])[0]; 
                    this_d = items;
                    // this.setState({
                    //     data : [
                    //         ...this.state.data.slice(0,ix),
                    //         cbRequest.formatDataForDataTable([m])[0],
                    //         ...this.state.data.slice(ix+1)
                    //     ]
                    // });
                    // const m = _.extend(indexToM[this_d[ix].index], this_d[ix])
                    // return [this_d[ix].index, cbRequest.formatDataForDataTable([m])[0] ];
                })
            }
            

        }
        var theData = [...this_d];
        // var data = cbRequest.formatDataForDataTable(tblData);
        //var data = cbRequest.formatDataForDataTable(this_d);
        console.log("retrieveDataFor: ", theData);
        
        //this.setState({ columns: tblCols});
        //this.setState({...this.state, dataLength : this.rawData.length});
        // this.setState({ dataLength : theData.length, currentLink : theProps.id, columns : tblCols});
        //resolve([theData, theProps.id, cols]);
        return theData;
    }

    async loadTableColumns(props) {
        const theProps = (props == null) ? this.props : props;
        console.log("loadTableColumns ", theProps)
        //Obtain the columns associated with this table
        const resp = await cbRequest.getTableColumnsFor(theProps.collection);

        var tblCols = (resp != null) ? Object.values(resp) : [];

        if (theProps.collection) {
            for (const col_ix in tblCols) {
                if (tblCols[col_ix].field == "discharge_date") {
                    tblCols[col_ix].defaultSort = "desc";
                    break;
                }
            }
        }

        console.log("tblCols: ",tblCols);
        this.tblCols = tblCols;
        this.currentLink = theProps.id;

        this.setState({ ...this.state, columns: tblCols});
    }

    async refreshTable(ref, refreshData ) {
        console.log("refreshTable")

        this.rawData = [];
        if (refreshData) {
            const data = await cbRequest.retrieveDataFor(this.props.collection, null);
            const d = this.processRetrievedData(data);
            this.rawData = d;
        }

        if ( _.isNull(ref) ){
            ref.current && ref.current.onQueryChange()
        }
        else {
            this.wrapper.current && this.wrapper.current.onQueryChange()
        }
        
        
    }
    
    loadTableWith(props) {
        // cb()
        // return
        // if (cbRequest.unsubscribe != null ){
        //     cbRequest.unsubscribe();
        // }
        return new Promise( async (resolve, reject) => {
            // console.log("loadTableWith")
            const theProps = (props == null) ? this.props : props;
            console.log("loadTableWith ", theProps)
            //Obtain the columns associated with this table
            //cbRequest.getTableColumnsFor(theProps.collection, (resp) => {
            const resp = await cbRequest.getTableColumnsFor(theProps.collection);
            // completeReq(resp);
            // //Mother's table needs list of all available Hospitals
            // if (theProps.collection == "mothers") {
                
            //     // cbRequest.retrieveDataFor("hospitals", (resp) => {
            //     //     //create the lookup object for the hospitals                    
            //     //     //let hospitalData = _.object( _.range(resp.length), _.map(resp, (x) => {return x["name"]}) );
            //     //     let hospitalData = {};
            //     //     resp.forEach((v,i,a) => {
            //     //         hospitalData[v["index"]] = ("name" in v) ? v["name"] : ""; 
            //     //     })
            //     //     console.log("hospitalData: ", hospitalData)
            //     //     //extend the hospital; first find the from_hospital field
            //     //     let fhIx = _.map(tblCols, (x) => { return x.field == "from_hospital" ? 1 : 0}).indexOf(1);
            //     //     tblCols[fhIx]["lookup"] = hospitalData;

            //     //     completeReq(tblCols);
            //     // });
            // }
            // else {
            //     completeReq(tblCols);
            // }
            var tblCols = (resp != null) ? Object.values(resp) : [];

            if (theProps.collection) {
                for (const col_ix in tblCols) {
                    if (tblCols[col_ix].field == "discharge_date") {
                        tblCols[col_ix].defaultSort = "desc";
                        break;
                    }
                }
            }

            //DEPRECATE??
            const performLoad = (resp, cols) => {
                const tblData = (resp != null) ? resp : [];
                // Current table data
                //var this_d = this.state.data;
                var this_d = this.rawData;
                //Remove data 
                if ("removed" in tblData && tblData["removed"].length > 0 ) {
                    //get the id's of data to remove
                    const idsToRemove = _.map(tblData.removed, (o) => {
                        return o.index;
                    });

                    //Get all data besides the ids to remove
                    this_d = this_d.filter(item => idsToRemove.indexOf(item.index) == -1 );
                }
                //Add data
                if ("added" in tblData && tblData["added"].length > 0) {
                    //ensure to only add different data
                    //..get the indexs to add
                    const idsToAdd =_.map(tblData.added, (o) => {
                        return o.index;
                    }); 
                    //get list of current indices
                    const currentIndices = _.map(this_d, (o) => {
                        return o.index;
                    })
                    // check if new data doesn't exist in current indices
                    const newDataIds = _.filter(idsToAdd, (o) => {
                        return currentIndices.indexOf(o) == -1;
                    })

                    const dataToAdd = cbRequest.formatDataForDataTable(_.filter(tblData.added, (o) => {
                        return newDataIds.indexOf(o.index) != -1;
                    }));
                    this_d = this_d.concat(dataToAdd);
                }
                if ("modified" in tblData && tblData["modified"].length > 0) {

                    //this.loadTableWith(null); return;
                    //get all the keys (index's) that have been modified
                    const indexToM = {};
                    const modKeys = _.map(tblData.modified, (o,i) => { 
                        indexToM[o.index] = o;
                        return o.index;
                    });
                    
                    // find matches based on 'index'; filter out matching indices
                    const updated = _.filter(_.map(this_d, (o,i) => {
                        if ( 'index' in o && modKeys.indexOf(o.index) > -1 ) {
                            return i;
                        }
                        else{ 
                            return -1;
                        }
                    }), (o) => { return o > -1});
                    console.log("Updated: ", updated)
                    // updated.forEach( (x) => {
                    //     this_d[x] = 
                    // })
                    //needs to just add this
                    if (updated.length == 0) {
                        const dataToAdd = cbRequest.formatDataForDataTable(tblData.modified);
                        this_d = this_d.concat(dataToAdd);
                    }
                    else {
                        // _.map(this_d, (o,i) => {
                        //     if ( o.index in indexToM) {
                        //         const m = _.extend(indexToM[o.index], o)
                        //         this_d[i] = cbRequest.formatDataForDataTable([m])[0];
                        //     }
                        // })
                        _.map(updated, (ix) => {
                            const m = _.extend(this_d[ix], indexToM[this_d[ix].index]);

                            // 1. Make a shallow copy of the items
                            let items = [...this_d];
                            // 2. Make a shallow copy of the item you want to mutate
                            //let item = {...items[ix]};
                            // 3. Replace the property you're intested in
                            //item.name = 'newName';
                            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                            items[ix] = cbRequest.formatDataForDataTable([m])[0]; 
                            this_d = items;
                            // this.setState({
                            //     data : [
                            //         ...this.state.data.slice(0,ix),
                            //         cbRequest.formatDataForDataTable([m])[0],
                            //         ...this.state.data.slice(ix+1)
                            //     ]
                            // });
                            // const m = _.extend(indexToM[this_d[ix].index], this_d[ix])
                            // return [this_d[ix].index, cbRequest.formatDataForDataTable([m])[0] ];
                        })
                    }
                    

                }
                var theData = [...this_d];
                // var data = cbRequest.formatDataForDataTable(tblData);
                //var data = cbRequest.formatDataForDataTable(this_d);
                console.log("retrieveDataFor: ", theData);
                console.log("tblCols: ",cols);
                //this.setState({ columns: tblCols});
                //this.setState({...this.state, dataLength : this.rawData.length});
                // this.setState({ dataLength : theData.length, currentLink : theProps.id, columns : tblCols});
                resolve([theData, theProps.id, cols]);

                // return new Promise((resolve, reject) => {
                //     resolve(theData);
                // })
                // //Since modal is displayed, ensure the text history is kept in sync 
                // if (this.state.modal.showModal && this.state.modal.id.length > 0){

                //     //match texthistory
                //     const mom = _.filter(theData, (el) => { return el.index == this.state.modal.id})[0];
                //     this.setState({...this.state, columns : tblCols, data: theData, currentLink : theProps.id, modal : {
                //         title : mom["full_name"], 
                //         id : mom["index"] , 
                //         phone : mom["telephone"],
                //         textHistory : mom["text_history"],
                //         showModal: true
                //     }});
                // }
                // else {
                //     //
                //     this.setState({...this.state,  columns : tblCols, data: theData, currentLink : theProps.id});
                // }
            }
            console.log("tblCols: ",tblCols);
            this.tblCols = tblCols;
            this.currentLink = theProps.id;
            //Register for callback of async data (pushed from server)
            //this.asyncCallbackId = cbRequest.registerForDataCallback(performLoad);
            // cbRequest.retrieveDataFor(theProps.collection, null)
            // cbRequest.retrieveDataFor(theProps.collection, null).then( (resp) => {
            //     //performLoad(resp);

            //     // setTimeout( () => {
            //     //     this.setState({  dataLength : resp.length, currentLink : this.currentLink, columns : this.tblCols});
            //     // },0)
            //     this.setState({  dataLength : resp.length, currentLink : this.currentLink, columns : this.tblCols});
            //     const d = this.processRetrievedData(resp);
            //     resolve(d)
            // });
            const data = await cbRequest.retrieveDataFor(theProps.collection, null);
            //performLoad(resp);
            this.setState({  dataLength : data.length, currentLink : this.currentLink, columns : this.tblCols});
            const d = this.processRetrievedData(data);
            resolve(d)
        })
    }

    // Applies sorting (asc/desc) for all colunns (including SMS) and searches full_name and text_history
    applyFilter(data, query) {

        //Check if orderBy is set, if not orderBy discharge_date, descending
        // const orderBy_field = ( !_.isNull(query) &&  'orderBy' in query && 'field' in query.orderBy) ? query.orderBy.field : "discharge_date";
        // const orderBy_ord =  (!_.isNull(query) && 'orderBy' in query && 'defaultSort' in query.orderBy) ? query.orderBy.defaultSort : "desc";
        const orderBy_field = ( !_.isNull(query) &&  'orderBy' in query && !_.isUndefined(query.orderBy)) ? query.orderBy.field : "discharge_date";
        const orderBy_ord =  (!_.isNull(query) && 'orderDirection' in query && !_.isUndefined(query.orderDirection)) ? query.orderDirection : "desc";
        
        var dd = [];

        // is the field a timestamp?
        if (cbRequest.getTimestampFields().indexOf(orderBy_field) != -1) {
            dd = _.sortBy(data, (o) => {
                return (orderBy_field in o && !_.isNull(o[orderBy_field])) ? moment(o[orderBy_field],"MM/DD/YYYY").unix() : 0;
            });
        }
        else { 
            dd = _.sortBy(data, (o) => {
                return o[orderBy_field]
            })
        }

        //Is this the SMS column?
        if ( orderBy_field == "SMS") {
            dd = _.sortBy(data, (o) => {
                const needsResponse = ("text_history" in o && o["text_history"].length > 0 && o["text_history"][o["text_history"].length-1].to_mom == false) ? true : false;
                return (needsResponse) ? 1 : -1;
            })
        }

        if ( orderBy_ord == "desc" || orderBy_ord == "" ){
            dd.reverse();
        }

        //Apply any search terms
        if (query.search.length > 0) {

            const fuse = new Fuse(dd, {
                keys: ['full_name', 'text_history.text'],
                threshold : 0.4
            })
            const rez = fuse.search(query.search);
            dd = _.map(rez, (r) => { return dd[r.refIndex]})
            // dd = _.filter(dd, (o) => {
            //     // return (o.full_name.toLowerCase().indexOf(query.search.toLowerCase()) != -1);
            //     return fuse.search();
            // })
        }
        return dd;
    }
    
    // 
    render() {
        // const tableRef = React.createRef();
        const tableRef = this.wrapper;
        const refreshRef = React.createRef();

        //const rowCount = this.state.data.length;
        return (
            <div >
                {/* Modal */}
                {/* <CBTextingModal modal={this.state.modal}/> */}
                <MommiTextingModal modal={this.state.modal}/>

                {/* Table */}
                <MaterialTable
                    tableRef={tableRef}
                    key={this.state.dataLength}
                    icons={tableIcons}
                    columns={this.state.columns}
                    //data={this.state.data}
                    data= { query => {

                            console.log("onDataQuery")
                            console.log(query);

                            return new Promise( async (resolve, reject) => {
                                // resolve({
                                //     data : [],
                                //     page: query.page,
                                //     //pageSize : resp.length,
                                //     totalCount: 0
                                // })
                                // return;
                                //Obtain the columns associated with this table
                                //cbRequest.getTableColumnsFor(theProps.collection, (resp) => {
                                //const resp = await cbRequest.getTableColumnsFor(this.props.collection);
                                // //Setup a listener
                                // this.asyncCallbackId = cbRequest.registerForDataCallback( (resp) => {
                                //     //this.wrapper.current.onQueryChange();
                                //     // const d = this.processRetrievedData(resp);
                                //     // //..track the data and data length
                                //     // this.rawData = d;
                                //     // // _.delay(() => {
                                //     // //     this.setState({...this.state, dataLength : this.rawData.length});
                                //     // // },0)
                                //     // //const dd = resp[0];
                                //     // // const currentLink = resp[1];
                                //     // // const cols = resp[2];
                                //     // //wrap in timeout to avoid memory leak
                                //     // setTimeout( () => {
                                //     //     this.setState({  dataLength : d.length, currentLink : this.currentLink, columns : this.tblCols});
                                //     // })
                                //     // //fulfill promise
                                //     // resolve({
                                //     //     data : applyFilter(d),
                                //     //     page: query.page,
                                //     //     //pageSize : resp.length,
                                //     //     totalCount: d.length
                                //     // })
                                // });
                                
                                // setTimeout( () => {
                                //     this.setState({  dataLength : this.rawData.length, currentLink : this.currentLink, columns : this.tblCols});
                                // },0);

                                // check the last time search was performed

                                //Check if there is already data 
                                if (this.rawData.length == 0)  {
                                    
                                    const data = await cbRequest.retrieveDataFor(this.props.collection, null);
                                    
                                    //performLoad(resp);
                                    //this.setState({...this.state,  dataLength : data.length, currentLink : this.currentLink});
                                    
                                    const d = this.processRetrievedData(data);
                                    this.rawData = d;
                                    resolve({
                                        data : this.applyFilter(d,query),
                                        page: query.page,
                                        //pageSize : resp.length,
                                        totalCount: d.length
                                    })
                                    // this.loadTableWith(null).then( (resp) => {

                                    //     console.log("loadTableWith promise data query fulfilled")
                                    //     // //..track the data and data length
                                    //     this.rawData = resp;
                                    //     // _.delay(() => {
                                    //     //     this.setState({...this.state, dataLength : this.rawData.length});
                                    //     // },0)
                                    //     // const dd = resp;
                                    //     // const currentLink = resp[1];
                                    //     // const cols = resp[2];
                                    //     //wrap in timeout to avoid memory leak
                                    //     // setTimeout( () => {
                                    //     //     //this.setState({  dataLength : resp.length, currentLink : this.currentLink, columns : this.tblCols});
                                            
                                    //     // },200)
                                    //     resolve({
                                    //         data : applyFilter(resp),
                                    //         page: query.page,
                                    //         //pageSize : resp.length,
                                    //         totalCount: resp.length
                                    //     })
                                    //     //this.wrapper.current.onQueryChange();
                                    //     //fulfill promise
                                        
                                    // })
                                }
                                // Use current data
                                else {
                                    console.log("use current data")
                                    // setTimeout( () => {
                                    //     this.setState({  dataLength : this.rawData.length});
                                    // })
                                    //const d = this.processRetrievedData(this.rawData);
                                    resolve({
                                        data : this.applyFilter(this.rawData, query),
                                        page: query.page,
                                       // pageSize : this.rawData.length,
                                        totalCount: this.rawData.length
                                    })
                                }
                                
                            })
                        }
                    }
                    title={this.props.title}
                    options={{
                        pageSize: (this.state.dataLength == 0) ? 10 : this.state.dataLength,
                        //pageSize:0,
                        actionsColumnIndex : (this.props.collection == "mothers") ? 1 : 0,
                        exportButton: true,
                        sorting : true,
                        //padding:"dense"
                        //emptyRowsWhenPaging : true
                        exportMenu: [{
                            label: 'Export PDF',
                            exportFunc: (cols, datas) => ExportPdf(cols, datas, this.props.collection + "_" + moment().format("MM-DD-YYYY_HH:mm:ss") )
                        }, {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, this.props.collection + "_" + moment().format("MM-DD-YYYY_HH:mm:ss") )
                        }]
                    }}
                    editable={{
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            // setTimeout(() => {
                            //   //setData([...this.state.data, newData]);
                              
                            //   //Pass for creating
                            //   cbRequest.addDataTo(this.props.collection, newData, () => {
                            //       //reload the data
                            //       this.loadTableWith(null,() => {});
                            //   })
                            //   resolve();
                            // }, 1000)
                            //console.log("New Data: ", newData)
                            //Pass for creating
                            // cbRequest.addDataTo(this.props.collection, newData, (idCreated) => {
                            //     newData.index = idCreated;
                            //     //this.setState([...this.state.data, newData]);
                            //     this.setState({ data: [...this.state.data, ...newData ] })
                            //     //reload the data
                            //     //this.loadTableWith(null,() => {});
                            //     resolve();
                            //     // return true;
                            // });
                            
                            cbRequest.addDataTo(this.props.collection, newData).then( (idCreated) => {
                                // newData.index = idCreated;
                                // this.setState([...this.state.data, newData]);
                                //this.setState({ data: [...this.state.data, ...newData ] })
                                //reload the data
                                //this.loadTableWith(null,() => {resolve();});
                                // resolve();
                                // return true;
                            }, (error) => {
                                console.log("addDataTo error: ", error)
                                reject(error);
                            });

                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            // setTimeout(() => {

                            //     const dataUpdate = [...this.state.data];
                            //     const index = oldData.tableData.id;
                            //     dataUpdate[index] = newData;
                            //     //setData([...dataUpdate]);
                                
                            //     //Pass for updating
                            //     cbRequest.updateDataFor(this.props.collection, newData, () => {
                            //         this.loadTableWith(null,() => {});
                            //     });

                            //     resolve();
                            // }, 1000)

                            const dataUpdate = [...this.state.data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            //setData([...dataUpdate]);
                            console.log("onRowUpdate")
                            //Pass for updating
                            cbRequest.updateDataFor(this.props.collection, newData). then(() => {
                                //this.loadTableWith(null);
                                //this.setState([...this.state.data, dataUpdate]);
                                //this.setState({...this.state,  data: dataUpdate});
                                resolve();
                            });

                            
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            // setTimeout(() => {
                            //     const dataDelete = [...this.state.data];
                            //     const index = oldData.tableData.id;
                            //     dataDelete.splice(index, 1);
                            //     //setData([...dataDelete]);
                                
                            //     //Pass for removal
                            //     cbRequest.deleteDataFor(this.props.collection, oldData, () => {
                            //         this.loadTableWith(null,() => {});
                            //     })
                            //     resolve();
                            // }, 1000)

                            const dataDelete = [...this.rawData];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            //setData([...dataDelete]);
                            
                            //Pass for removal
                            cbRequest.deleteDataFor(this.props.collection,this.rawData[index], () => {
                                //this.loadTableWith(null,() => {});
                            })
                            resolve();
                           // return true;
                          }),
                      }}

                    actions={[
                        {
                          icon: () => {
                              return <Refresh ref={refreshRef}/>
                          },
                          tooltip: 'Refresh Data',
                          isFreeAction: true,
                          onClick: () =>  this.refreshTable(tableRef,false) ,
                        }
                    ]}
                    // actions = { (this.props.title == "Mothers")  ?  
                    //     [{
                    //         icon: (props) => {
                    //             return  <SmsBadgeIcon showBadge={props.showBadge}/> 
                    //         },
                    //         //icon : Sms,
                    //         iconProps : {
                    //             showBadge : 1
                    //         },
                    //         tooltip: 'Send SMS',
                    //         onClick: (event, rowData) => {
                    //             if ("full_name" in rowData  && "telephone" in rowData && "index" in rowData) {
                    //                 this.setState({...this.state, modal : { 
                    //                     title : rowData["full_name"], 
                    //                     id : rowData["index"] , 
                    //                     phone : rowData["telephone"],
                    //                     textHistory : rowData["text_history"],
                    //                     showModal: true
                    //                 }});
                    //             }
                    //         }
                    //     }] : null }
                    />
            </div>
            
        );
    }
    
};


export default CBTable;

