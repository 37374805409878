import React, { useRef, useEffect, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@material-ui/icons/Image';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import _ from 'underscore';
import MMSManager from "./MMSManager.js";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

/*


*/
class MommiChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: makeStyles({
                table: {
                    minWidth: 650,
                },
                chatSection: {
                    width: '100%',
                    height: '80vh'
                },
                headBG: {
                    backgroundColor: '#e0e0e0'
                },
                borderRight500: {
                    borderRight: '1px solid #e0e0e0'
                },
                messageArea: {
                    height: '70vh',
                    overflowY: 'auto'
                }
            }),
            textHistoryObj: {
                textHistory: props.textHistory,
                onSend: props.onSend
            },
            smsMsg: "",
            helperText: "",
            disableSend: props.disableSMS
        }
        this.divRef = React.createRef();
        //this.divRef = useRef();
        this.updateSms = this.updateSms.bind(this);
        this.sendText = this.sendText.bind(this);
    }
    //Updates the chat modal text to send as SMS
    updateSms(evt) {
        //console.log(evt)
        if (_.isNull(evt) || _.isNull(this)) return;

        this.setState({
            ...this.state,
            smsMsg: evt.target.value
        })
    }
    sendText() {
        if (this.state.smsMsg.length == 0) { return; }
        //re-set the helper text when about to send SMS
        this.setState({
            ...this.state,
            helperText: "Sending SMS...",
            disableSend: true

        });

        //Trigger text and update modal with status
        this.state.textHistoryObj.onSend(this.state.smsMsg, (sent) => {

            this.setState({
                ...this.state,
                helperText: sent ? "Message Sent!" : "Error sending message",
                disableSend: false
            })
            //setHelperText( sent ? "Message Sent!" : "Error sending message" );
            //Clear message after 2 seconds
            setTimeout(() => {
                //setHelperText("");
                // this.setState({ ...this.state, 
                //     helperText : ""
                // })

                this.divRef.current.scrollIntoView({ behavior: 'smooth' });
                if (sent) {
                    //setSMS("");
                    this.setState({
                        ...this.state,
                        smsMsg: "",
                        helperText: ""
                    })
                }

            }, 1000);
        });
    }

    componentWillReceiveProps(nextProps) {
        console.log("MommiChat componentWillReceiveProps ", nextProps);

        this.setState({
            ...this.state,
            textHistoryObj: nextProps
        })
        // if (this.state.currentLink != null && this.state.currentLink == nextProps.id) { return;}
        // this.loadTableWith( nextProps);
        //this.divRef.current.scrollIntoView({ behavior: 'smooth' });

    }

    componentDidMount() {
        console.log("MommiChat componentDidMount");
        _.delay(() => {
            this.divRef.current.scrollIntoView({ behavior: 'smooth' });
            this.divRef.current.focus();
        }, 500);


    }

    componentWillUnmount() {
        console.log("MommiChat componentWillUnmount");
    }

    render() {

        return (
            <div>
                <Grid container component={Paper} className={this.state.classes.chatSection}>

                    <Grid item xs={12}>
                        <List className={this.state.classes.messageArea}>

                            {(this.state.textHistoryObj.textHistory == null || this.state.textHistoryObj.textHistory.length == 0) ?
                                <Alert severity="info">No texts have been sent yet..</Alert>
                                :
                                this.state.textHistoryObj.textHistory.map((value, index) => {

                                    if ("originator" in value) {
                                        return (
                                            <Grid container spacing={0} key={index + 1} style={{ padding: '10px', overflowWrap: 'anywhere' }}>
                                                <Grid item xs={12} >
                                                    <ListItemText align={value.to_mom ? "right" : "left"} primary={value.text} secondary={moment(value.time).format("MM/DD/YYYY hh:mm:ss A") + " (" + value.originator + ")"} ></ListItemText>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    return (
                                        <Grid container spacing={0} key={index + 1} style={{ padding: '10px', overflowWrap: 'anywhere' }}>
                                            <Grid item xs={12} >
                                                <ListItemText align={value.to_mom ? "right" : "left"} primary={value.text} secondary={moment(value.time).format("MM/DD/YYYY hh:mm:ss A")}></ListItemText>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                        </List>

                        <Divider />

                        <Grid container style={{ padding: '20px' }}>
                            <Grid item xs={11} >
                                <TextField id="outlined-basic-email" label="Enter Message" fullWidth multiline helperText={this.state.helperText} onChange={this.updateSms} value={this.state.smsMsg} ref={this.divRef} />
                            </Grid>
                            <Grid align="center">
                                <Fab color="primary" aria-label="add" disabled={this.state.disableSend}>
                                    <SendIcon onClick={this.sendText} />
                                </Fab>
                            </Grid>
                            {/* Handles file uploads for MMS */}
                            {/* <MMSManager /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </div>


        );
    }
}

//export default Chat;
export default MommiChat;