import React, { useRef, useEffect, useState, Component } from 'react';
import ImageIcon from '@material-ui/icons/Image';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
//import {FileLibraryListItem, ReactMediaLibrary, FileMeta} from 'react-media-library';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import cbRequest from "./CBRequest.js";

const _ = require("underscore");
function MMSManager(props) {

    const [itemData, setItemData] = useState([]);
    // const [display, setDisplay] = useState(true);
    // const [fileLibraryList, setFileLibraryList] = useState([]);
    const classes = makeStyles({
        table: {
            minWidth: 650,
        },
        chatSection: {
            width: '100%',
            height: '80vh'
        },
        headBG: {
            backgroundColor: '#e0e0e0'
        },
        borderRight500: {
            borderRight: '1px solid #e0e0e0'
        },
        messageArea: {
            height: '70vh',
            overflowY: 'auto'
        }
    })
    useEffect( async () => {
        //Get all the current MMS uploads
        const mms = await cbRequest.getAllMMSUploads();
        console.log(mms)
        setItemData(mms.data);
        
    },[]);

    function clientSelectCallback(item) {

    }
    async function uploadCallback(fileBase64, fileMeta) {
        // TODO Upload file to backend APIs
        // const result = await fileUpload(fileBase64, fileMeta);

        // if (result.success) {
        //     // New file uploaded
        //     // TODO Reacquire new file list from database
        //     const newFileList = await getNewFileList();
        //     setFileLibraryList(newFileList);

        //     // Return true to display upload success in modal
        //     return true;
        // } else {
        //     // Return false to display upload failed in modal
        //     return false;
        // }
        return true
    }

    function selectCallback(item) {
        // Hide modal
       // setDisplay(false);

        // TODO Pass selected file back to client component callback function
        clientSelectCallback(item);
    }

    async function deleteCallback(item) {
        // TODO Delete file from backend service
        // const result = await fileDelete(item);
        
        // if (result.success) {
        //     // Deleted file 
        //     // TODO Reacquire file list from database
        //     const newFileList = await getNewFileList();
        //     setFileLibraryList(newFileList);
        // }
    }

    return (
        
        <div>
            {/* File upload support for MMS */}
            <Grid item xs={12}>
                <h3>MMS Browser</h3>
                <React.Fragment>
                    {/*    */}
                    <ImageList sx={{ width: "100%", height: 250 }} cols={3} rowHeight={164}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.name} class="mms-parent">
                                <img
                                    src={`${item.url}&w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.url}&w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.name}
                                    loading="lazy"
                                    style={ {"objectFit" : "none"} }
                                />
                                <button class="close">X</button>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </React.Fragment>
            </Grid>
        </div>
        
    )
}

export default MMSManager;